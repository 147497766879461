<template>
  <land-section
    id="party-answer"
    :color="backColor"
    :space="space"
  >
    <land-section-heading
      v-if="header.icon || header.title"
      v-bind="$attrs"
    >
      {{ header.subtitle }}
    </land-section-heading>

    <v-container>
      <v-row
        justify="space-between"
        align="start"
      >
        <v-col
          v-for="(app, index) in partyApps"
          :key="`app_${index}`"
          cols="12"
          md="6"
        >
          <v-hover
            v-slot="{ hover }"
            :disabled="!hovered"
            close-delay="120"
          >
            <v-card
              :elevation="hover ? hoverElevation : normalElevation"
              :class="[round, 'transition-swing']"
              color="white"
              flat
              tile
            >
              <v-img
                :src="app.cover"
                aspect-ratio="2"
                class="align-end"
                height="inherit"
              />
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </land-section>
</template>

<script>
  import mixPartyApp from '@/pages/mixins/party/mix.party.app'

  export default {
    name: 'SectionPartyAnswer',
    mixins: [mixPartyApp],
    props: {
      header: {
        type: Object,
        default: () => {
          return {}
        }
      },
      backColor: {
        type: String,
        default: 'grey lighten-4'
      },
      space: {
        type: [Number, String],
        default: 56
      }
    },
    data () {
      return {
        hovered: true,
        normalElevation: 0,
        hoverElevation: 22,
        round: 'rounded-lg'
      }
    },
  }
</script>
