
export default {
  data () {
    return {
      partyApps: [{
        name: '',
        title: '答题练习',
        cover: 'https://resources.landcoo.com/30958445909770240.png'
      }, {
        name: '',
        title: '答题测试',
        cover: 'https://resources.landcoo.com/30958449517264896.png'
      }]
    }
  }
}
